



















































































import Vue from 'vue';
import store from '@/store'
import {mapActions} from 'vuex';
import {checkVersion, getVersion} from '@/utils/version';
import {axiosGet} from '@/utils/axiosWrapper';
import {IUser} from "../../../api/models/user";

export default Vue.extend({
  name: 'Dashboard',
  data() {
    return {
      user: {
        name: "",
        email: ""
      } as IUser,
      userId: store.state.accessToken,

      menuVisible: false,

      showVersionPopup: checkVersion(),
      version: getVersion()
    }
  },
  methods: {
    ...mapActions([
      'logout'
    ]),
    loadData() {
      return axiosGet('/users/' + this.userId)
          .then(function (response: { data: { data: IUser }; }) {
            return response.data.data
          })
          .catch(function (error: Error) {
            console.log(error);
          });
    }
  },
  async mounted() {
    const user = await this.loadData()
    if (user)
      this.user = user
    else
      console.warn("Houston! User was not found! -> Problem")
  },
});
